<template>
  <thead>
    <tr>
      <th class="col-1">
        <v-layout justify-center>
          {{ `${$t('weight')} (${$t('kg')})` }}
        </v-layout>
      </th>
      <th class="col-2">
        <v-layout justify-center>
          {{ $t('delivery_method') }}
        </v-layout>
      </th>
      <th>
        <v-layout justify-center>
          {{ `${$t('intra_provincial_prices')} (${$t('vnd')})` }}
        </v-layout>
      </th>
      <th>
        <v-layout justify-center>
          {{ `${$t('area_prices')} (${$t('vnd')})` }}
        </v-layout>
      </th>
      <th>
        <v-layout justify-center>
          {{ `${$t('inter_regional_price')} (${$t('vnd')})` }}
        </v-layout>
      </th>
    </tr>
  </thead>
</template>
<script>
export default {
  name: 'HeaderPriceList'
}
</script>
